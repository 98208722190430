import React from "react";
import { graphql, Link } from "gatsby";
import YouTube from 'react-youtube';
import Helmet from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import { Container, Row, Col } from 'reactstrap';

import Layout from "../components/layout";

export default ({ data, pageContext }) => {
  const node = data.videosJson;
  const opts = {
    playerVars: { // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      rel: 0,
      origin: 'https://wedeography.com'
    }
  };

  return (
    <Layout noPadding>
      <Helmet title={node ? node.title : 'Video Loading...'}>
        <link rel="canonical" href={`https://wedeography.com${pageContext.slug}`} />
        <meta name="og:url" content={`https://wedeography.com${pageContext.slug}`} />
      </Helmet>
      <div className="embed-responsive embed-responsive-16by9 rounded-top">
        {node && <YouTube
          className="embed-responsive-item"
          videoId={node.fields.videoId}
          opts={opts}
        />}
      </div>
      <Container className="text-center mb-4">
        <Row>
          <Col xs={12} className="mt-3">
            <h2>
              <span className="d-block">{node.title}</span>
              <small className="text-muted">{node.client}</small>
            </h2>
            <small>Produced {node.year}</small>
            <br />
            {node.details && node.details.trim().length > 1 &&
              <div>
                <hr className="mt-2 mb-2" />
                <ReactMarkdown source={node.details} />
              </div>
            }
          </Col>
        </Row>
        <Row>
          <Col xs={12}><hr /></Col>
          <Col>Like what you see? <Link to="/contact">Let's chat!</Link></Col>
        </Row>
      </Container>
    </Layout>
  )
};

export const query = graphql`
  query($slug: String!) {
    videosJson(fields: { slug: { eq: $slug } }) {
      title
      year
      client
      youtube_url
      details
      fields {
        videoId
      }
    }
  }
`
export const defaultProps = {
  data: {
    name: "Loading",
    year: 2019,
    client: "Loading",
    fields: {
      videoId: ''
    }
  }
};
